<!-- eslint-disable vue/no-v-html -->
<template>
  <div v-if="isFullLoaded">
    <ConfirmModal2
      ref="confirmModal"
      variant="danger"
      :title="confirmModal.title"
      :description="confirmModal.description"
    />
    <ConfirmModal
      v-model="isConfirmModalActive"
      :items="addItem"
      title="Confirm to Add Ticket"
      @confirm="confirmAdd()" />
    <ReOpenConfirmModal
      ref="reOpenConfirmModal"
      :remark.sync="remark"
      @update-remark="remark = $event"
    />
    <ReferModal
      ref="referModal"
      :ticket-code-refer.sync="ticketCodeRefer"
      @update-ticket-code-refer="ticketCodeRefer = $event"
    />
    <TransferModal
      ref="transferModal"
    />
    <StatusModal
      v-model="isSubmitModalActive"
      status-title="Confirm to Add Ticket"
      status-color="#28C76F"
      status-icon="CheckCircleIcon"
      @discard="onDiscard()" />
    <KnowledgeModal
      v-if="isKnowledgeModal"
      ref="knowledgeModal"
      v-model="isKnowledgeModal"
      :is-form-disabled="isFormDisabled || isFormStatus"
      :knowledge-items="knowledgeItems"
      :pagination="knowledgePagination"
      :search.sync="searchKnowledge"
      :ticket="ticket"
      :lvl-options="lvlOptions"
      :categories="allRef.categories"
      @fetch-search="fetchSearchKnowledge()"
      @selectd-row="selectdKnowledge($event)"
      @show-current-page="showCurrentPageKnowledge($event)"
    />
    <SendToEmailModal
      v-if="isSendEmailModal"
      v-model="isSendEmailModal"
      :init-subject.sync="subjectModifier"
      :init-message.sync="messageModifier"
      @click="sendEmail($event)"
      @send-email="sendEmail($event)"
    />
    <b-card
      @hidden="resetForm()">
      <template #default="{}">
        <ValidationObserver
          #default="{ handleSubmit }"
          ref="TicketAddForm">
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(updateTicket)"
            @reset.prevent="resetForm()">
            <!-- <b-row v-if="ticket && ticket.isNoneVoice"> -->
            <b-row>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="ticket.transactionId"
                  :rules="{ required: false }"
                  name="transactionId"
                  label="Transaction ID (Non-Voice)"
                  :disabled="isFormDisabled || isFormStatus"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="ticket.contactId"
                  :rules="{ required: false }"
                  name="Ticket ID"
                  label="Contact ID (Non-Voice)"
                  :disabled="isFormDisabled || isFormStatus"
                  trim />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  :value="ticket.code"
                  :rules="{ required: false }"
                  name="Ticket ID"
                  :label="`Ticket ID ${ticket.ticketCodeRefer ? `(Refer: ${ticket.ticketCodeRefer})` : ''}` "
                  disabled
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  :value="ticket.createdAt | formatDateTimeEn"
                  :rules="{ required: false }"
                  name="Open Due"
                  label="Open Due"
                  disabled
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-if="ticket && ticket.progressBy"
                  :value="`${ticket.progressBy.firstName} ${ticket.progressBy.lastName}`"
                  :rules="{ required: false }"
                  name="Progress By"
                  label="Progress By"
                  disabled
                  trim />
              </b-col>
              <!-- <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="ticket.ticketStatus"
                  :disabled="isFormDisabled"
                  :rules="{ required: false }"
                  name="Ticket Status"
                  form-label="Ticket Status"
                  label-option="label"
                  return-value="value"
                  :options="ticketStatusOption" />
              </b-col> -->
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="ticket.ticketTypeId"
                  :rules="{ required: true }"
                  name="Ticket Type"
                  form-label="Ticket Type"
                  placeholder="Choose incidentType"
                  label-option="name"
                  :disabled="isFormDisabled || isFormStatus"
                  return-value="id"
                  :options="allRef.ticketType"
                  @input="chooseTicketType($event)"
                />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="ticket.customerName"
                  :rules="{ required: false }"
                  :disabled="isFormDisabled || isFormStatus"
                  name="Customer Name"
                  label="Customer Name"
                  placeholder="Input Customer Name"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="ticket.contactName"
                  :rules="{ required: false }"
                  :disabled="isFormDisabled || isFormStatus"
                  name="Contact Name"
                  label="Contact Name"
                  placeholder="Input Contact Name"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="ticket.contactPhoneNo"
                  :is-can-call="true"
                  :disabled="isFormDisabled || isFormStatus"
                  :rules="{ required: false, }"
                  name="Contact Phone Number"
                  label="Contact Phone Number"
                  placeholder="Input Contact Phone Number"
                  trim
                />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="ticket.contactChannelId"
                  :rules="{ required: false }"
                  :disabled="isFormDisabled || isFormStatus"
                  name="Contact Channel"
                  form-label="Contact Channel"
                  placeholder="Choose Contact Channel"
                  label-option="name"
                  return-value="id"
                  :options="allRef.contactChannel"
                />
              </b-col>
            </b-row>
            <b-row class="align-items-end">
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="ticket.categoryLv1"
                  :disabled="!lvlOptions.lvlOneOptions || lvlOptions.lvlOneOptions.length <= 0 || isFormDisabled || isFormStatus"
                  name="Category level 1"
                  form-label="Category level 1"
                  placeholder="Category level 1"
                  return-value="id"
                  label-option="name"
                  :rules="{ required: isRequiredCategory }"
                  :options="lvlOptions.lvlOneOptions"
                  @input="selectedItem($event, 1)"
                />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="ticket.categoryLv2"
                  :disabled="!lvlOptions.lvlTwoOptions || lvlOptions.lvlTwoOptions.length <= 0 || isFormDisabled || isFormStatus"
                  name="Category level 2"
                  form-label="Category level 2"
                  placeholder="Category level 2"
                  :options="lvlOptions.lvlTwoOptions"
                  :rules="{ required: checkRequireCategory(lvlOptions.lvlTwoOptions) }"
                  return-value="id"
                  label-option="name"
                  @input="selectedItem($event, 2)"
                />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="ticket.categoryLv3"
                  :disabled="!lvlOptions.lvlThreeOptions || lvlOptions.lvlThreeOptions.length <= 0 || isFormDisabled || isFormStatus"
                  name="Category level 3"
                  form-label="Category level 3"
                  placeholder="Category level 3"
                  :options="lvlOptions.lvlThreeOptions"
                  :rules="{ required: checkRequireCategory(lvlOptions.lvlThreeOptions) }"
                  return-value="id"
                  label-option="name"
                  @input="selectedItem($event, 3)"
                />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="ticket.categoryLv4"
                  :disabled="!lvlOptions.lvlFourOptions || lvlOptions.lvlFourOptions.length <= 0 || isFormDisabled || isFormStatus"
                  name="Category level 4"
                  form-label="Category level 4"
                  placeholder="Category level 4"
                  :options="lvlOptions.lvlFourOptions"
                  :rules="{ required: checkRequireCategory(lvlOptions.lvlFourOptions) }"
                  return-value="id"
                  label-option="name"
                  @input="selectedItem($event, 4)"
                />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="ticket.categoryLv5"
                  :disabled="!lvlOptions.lvlFiveOptions || lvlOptions.lvlFiveOptions.length <= 0 || isFormDisabled || isFormStatus"
                  name="Category level 5"
                  form-label="Category level 5"
                  placeholder="Category level 5"
                  :options="lvlOptions.lvlFiveOptions"
                  return-value="id"
                  label-option="name"
                  :rules="{ required: checkRequireCategory(lvlOptions.lvlFiveOptions) }"
                  @input="selectedItem($event, 5)"
                />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="ticket.categoryLv6"
                  :disabled="!lvlOptions.lvlSixOptions || lvlOptions.lvlSixOptions.length <= 0 || isFormDisabled || isFormStatus"
                  name="Category level 6"
                  form-label="Category level 6"
                  placeholder="Category level 6"
                  :options="lvlOptions.lvlSixOptions"
                  return-value="id"
                  label-option="name"
                  :rules="{ required: checkRequireCategory(lvlOptions.lvlSixOptions) }"
                  @input="selectedItem($event, 6)"
                />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="ticket.priority"
                  :rules="{ required: false }"
                  name="Priority"
                  form-label="Priority"
                  placeholder="Choose Priority"
                  label-option="label"
                  return-value="value"
                  disabled
                  :options="priorityOption" />
              </b-col>
              <!-- <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  :value="resolveDate | formatDateTimeEn"
                  disabled
                  name="Resolve Date"
                  label="Resolve Date"
                  placeholder="Resolve Date"
                />
              </b-col> -->
              <!-- <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  :value="dueDate | formatDateTimeEn"
                  disabled
                  name="Due Date"
                  label="Due Date"
                  placeholder="Due Date"
                />
              </b-col> -->
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="ticket.plantId"
                  :rules="{ required: false }"
                  name="Plant"
                  form-label="Plant"
                  placeholder="Input Plant"
                  label-option="code"
                  return-value="id"
                  :disabled="isFormDisabled || isFormStatus"
                  :options="allRef.plant" />
              </b-col>
              <b-col
                sm="4"
                md="4">
                <TextFieldInput
                  v-model="ticket.ticketCause"
                  :rules="{ required: false }"
                  name="Incident Cause"
                  label="Incident Cause"
                  placeholder="Input Incident Cause"
                  :disabled="isFormDisabled || isFormStatus"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="ticket.qty"
                  :rules="{ required: false, numeric: true }"
                  name="Qty"
                  label="Qty (ครั้ง)"
                  placeholder="Input Qty"
                  trim
                  :disabled="isFormDisabled || isFormStatus"
                  @keypress="$keypressNumberNoDecimal()"
                />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TimePicker
                :disabled="isFormDisabled || isFormStatus"
                  v-model="ticket.time"
                  label="Time (hh:mm)"
                />
                <!-- <TextFieldInput
                  v-model="ticket.time"
                  v-mask="'##:##'"
                  :disabled="isFormDisabled || isFormStatus"
                  :rules="{ required: false}"
                  name="Time"
                  label="Time (hh:mm)"
                  placeholder="hh:mm"
                  trim
                  @keypress="$keypressNumber()"
                /> -->
              </b-col>
              <b-col
                sm="12"
                md="4">
                <b-form-group>
                  <b-button
                    variant="success"
                    :disabled="isFormDisabled || isFormStatus"
                    @click="showModal()">
                    Choose Knowledge
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="align-items-end">
              <b-col
                sm="4"
                md="4">
                <TextFieldInput
                  v-model="ticket.subject"
                  :rules="{ required: false }"
                  name="Subject"
                  label="Subject"
                  :disabled="isFormDisabled || isFormStatus"
                  placeholder="Input Subject"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="12">
                <b-form-group
                  label="Incident Detail"
                >
                  <div
                    v-if="!isFormDisabled && !isFormStatus"
                    class="my-2">
                    <PreviewSwitch
                      v-model="isDetailPreview"
                    >
                      Preview
                    </PreviewSwitch>
                  </div>
                  <div
                    v-if="isDetailPreview || isFormDisabled || isFormStatus"
                    class="box-preview"
                    v-html="ticket.ticketDetail">
                  </div>
                  <wysiwyg
                    v-else
                    v-model="ticket.ticketDetail"
                    :disable="isFormDisabled || isFormStatus"
                  />
                  <!-- <quill-editor
                    v-model="ticket.ticketDetail"
                    :rules="{ required: false }"
                    name="Incident Detail"
                    label="Incident Detail"
                    placeholder="Input Incident Detail"
                    trim /> -->
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="align-items-end">
              <b-col
                sm="12"
                md="12">
                <b-form-group
                  label="Incident Resolve">
                  <div
                    v-if="!isFormDisabled && !isFormStatus"
                    class="my-2">
                    <PreviewSwitch
                      v-model="isResolvePreview"
                    >
                      Preview
                    </PreviewSwitch>
                  </div>
                  <div
                    v-if="isResolvePreview || isFormDisabled || isFormStatus"
                    class="box-preview"
                    v-html="ticket.ticketResolve">
                  </div>
                  <wysiwyg
                    v-else
                    v-model="ticket.ticketResolve"
                    :disabled="isFormDisabled" />
                  <!-- <quill-editor
                    v-model="ticket.ticketResolve"
                    :rules="{ required: false }"
                    name="Incident Resolve"
                    label="Incident Resolve"
                    placeholder="Input Incident Resolve"
                    trim /> -->
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                sm="12"
                md="12">
                <TextAreaInput
                  v-model="ticket.remark1"
                  :disabled="isFormDisabled || isFormStatus"
                  :rules="{ required: false }"
                  :name="`Remark`"
                  :label="`Remark`" />
              </b-col>
            </b-row>
            <b-button
              v-if="!isFormDisabled && !isFormStatus"
              v-ripple.400="'rgba(94,88,115)'"
              style="background: #5E5873 !important"
              class="mt-3"
              type="button"
              @click="openFile()"
            >
              Attach Files
            </b-button>
            <input
              ref="addFiles"
              class="d-none"
              type="file"
              multiple
              :disabled="isFormDisabled || isFormStatus"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, image/*"
              @change="onChangeFile($event)"
            >
            <b-col
              class="my-2"
              cols="12">
              <ul>
                <li
                  v-for="(item, index) in attachFiles"
                  :key="index"
                  class="my-2"
                >
                  <span
                    class="relative">
                    <label class="remove-file">
                      <span
                        v-show="!isFormDisabled && !isFormStatus"
                        class="pointer"
                        @click="removeFile(index, `${item && item.file ? 'files' : 'attachFiles'}` )">
                        X
                      </span>
                    </label>
                    <a
                      :href="item.url"
                      target="_blank">{{ item.name }}</a>
                  </span>
                </li>
              </ul>
            </b-col>
            <div class="d-flex">
              <b-button
                v-if="!isFormStatus && !isFormDisabled"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :disabled="isFormDisabled"
                variant="success"
                class="mt-5 mr-2 btn-submit"
                type="submit"
              >
                Save
              </b-button>
              <b-button
                v-if="!isFormStatus && !isFormDisabled"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-5 mr-2 btn-submit"
                @click="saveAndClosed()"
              >
                Save and closed
              </b-button>
              <b-button
                v-if="!isFormStatus && !isFormDisabled"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                class="mt-5 mr-2 btn-submit"
                variant="primary"
                @click="transferTicket()">
                Transfer
              </b-button>
              <b-button
                v-if="!isFormStatus && !isFormDisabled"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="primary"
                type="button"
                class="mt-5 mr-2 btn-submit"
                @click="referTicket()">
                Refer
              </b-button>
              <b-button
                v-if="!isFormStatus && !isFormDisabled"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="warning"
                type="button"
                class="mt-5 mr-2 btn-submit"
                @click="showSendEmailModal()"
              >
                Send to email
              </b-button>
              <b-button
                v-if="!isFormStatus && !isFormDisabled"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="danger"
                type="button"
                class="mt-5 mr-2 btn-submit"
                @click="voidTicket()">
                Void
              </b-button>
              <b-button
                v-if="isFormStatus"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="primary"
                type="button"
                class="mt-5 mr-2 btn-submit"
                @click="duplicateTicket()">
                Duplicate
              </b-button>
              <b-button
                v-if="isFormStatus"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="success"
                type="button"
                class="mt-5 mr-2 btn-submit"
                @click="reOpenTicket()">
                Re Open
              </b-button>
            </div>
          </b-form>
        </ValidationObserver>
      </template>
    </b-card>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapGetters, mapActions } from 'vuex'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import PreviewSwitch from '@/components/PreviewSwitch.vue'
import TextFieldInput from '@/components/Form/TextFieldInput.vue'
import SelectField from '@/components/Form/SelectField.vue'
import TextAreaInput from '@/components/Form/TextAreaInput.vue'
import ConfirmModal from '@/components/Modal/ConfirmModal.vue'
import ConfirmModal2 from '@/components/ConfirmModal.vue'
import StatusModal from '@/components/Modal/StatusModal.vue'
import KnowledgeModal from '@/views/Tickets/components/KnowledgeModal.vue'
import TicketTypeProvider from '@/resources/TicketType'
import SendToEmailModal from '@/components/Modal/SendToEmailModal.vue'
import ContactChannleProvider from '@/resources/ContactChannelProvider'
import TimePicker from '@/components/Form/TimePicker.vue'
// import CallCategoryProvider from '@/resources/CallCategoryProvider'
import CategoriesProvider from '@/resources/CategoriesProvider'
import PlantProvider from '@/resources/PlantProvider'
import TicketProvider from '@/resources/TicketProvider'
import KnowledgeProvider from '@/resources/KnowledgeBaseProvider'
import UploadService from '@/resources/UploadProvider'
import MicrosoftProvider from '@/resources/MicrosoftProvider'
import ReOpenConfirmModal from './ReOpenConfirmModal.vue'
import ReferModal from './ReferModal.vue'
import TransferModal from './TransferModal.vue'
import resizeBase64ImagesInHTML from '@/utils/resize-image-base-64-html'
import roleEnum from '@/common/role.enum'
import ClickToCallModal from '@/components/Modal/ClickToCallModal.vue'

const ContactChannelService = new ContactChannleProvider()
// const CallCategoryServie = new CallCategoryProvider()
const CategoriesService = new CategoriesProvider()
const TicketService = new TicketProvider()
const TicketTypeService = new TicketTypeProvider()
const PlantService = new PlantProvider()
const KnowledgeService = new KnowledgeProvider()
const MicrosoftService = new MicrosoftProvider()

export default {
  components: {
    TimePicker,
    TextFieldInput,
    SelectField,
    TextAreaInput,
    ConfirmModal,
    ConfirmModal2,
    StatusModal,
    KnowledgeModal,
    SendToEmailModal,
    ReOpenConfirmModal,
    ReferModal,
    TransferModal,
    PreviewSwitch
  },
  props: {
    customerDetail: {
      type: Object,
      default: () => null
    },
    ticketProps: {
      type: Object,
      default: () => null
    }
  },
  data () {
    return {
      remark: '',
      ticketCodeRefer: null,
      isFullLoaded: false,
      searchKnowledge: '',
      isShowComponent: true,
      isConfirmModalActive: false,
      isSubmitModalActive: false,
      isSendEmailModal: false,
      isTransfer: false,
      isKnowledgeModal: false,
      isRequiredCategory: false,
      isDetailPreview: false,
      isResolvePreview: false,
      userItems: [],
      userName: '',
      confirmModal: {
        title: 'Are you sure ?',
        description: 'You want to Void ?'
      },
      tickets: {
        ticketTypeId: null,
        contactChannelId: null,
        callCategoryId: null,
        ticketCodeRefer: null,
        priority: '',
        customerName: '',
        contactName: '',
        contactPhoneNo: '',
        categoryLv1: null,
        categoryLv2: null,
        categoryLv3: null,
        categoryLv4: null,
        categoryLv5: null,
        categoryLv6: null,
        plantId: '',
        qty: '',
        time: '',
        subject: '',
        ticketCause: '',
        ticketResolve: '',
        ticketDetail: '',
        progressBy: {},
        dueDate: null,
        resolveDate: null,
        remark1: '',
        remark2: '',
        remark3: '',
        ticketStatus: ''
      },
      progressBy: null,
      priorityOption: [
        { label: 'Low', value: 'low' },
        { label: 'Medium', value: 'medium' },
        { label: 'High', value: 'high' }
      ],
      isRemark1: true,
      isRemark2: false,
      isRemark3: false,
      addItem: {},
      lvlOptions: {
        lvlOneOptions: [],
        lvlTwoOptions: [],
        lvlThreeOptions: [],
        lvlFourOptions: [],
        lvlFiveOptions: [],
        lvlSixOptions: []
      },
      allRef: {
        ticketType: [],
        customerContact: [],
        categories: [],
        plant: []
      },
      ticketStatusOption: [
        { label: 'Open', value: 'open' },
        { label: 'Closed', value: 'closed' },
        { label: 'Over Due', value: 'over Due' },
        { label: 'Void', value: 'void' }
      ],
      knowledgeItems: [],
      files: [],
      currentParentId: null,
      resolveDate: '',
      dueDate: '',
      knowledgePagination: {
        page: 1,
        totalDocs: 1,
        limit: 10
      }

    }
  },
  computed: {
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      profile: 'User/user',
      ticketsStore: 'ticket/tickets'
    }),
    ticket: {
      get () {
        return this.ticketProps
      },

      set (val) {
        this.$emit('update-ticket', val)
      }
    },
    checkClosedStatus () {
      return this.ticket.ticketStatus !== 'closed' || this.ticket.ticketStatus !== 'void'
    },
    ticketId () {
      return this.$route.params.id
    },
    attachFiles () {
      const result = []
      if (this.ticket.attachFiles.length > 0) {
        result.push(...this.ticket.attachFiles)
      }
      if (this.files.length > 0) {
        result.push(...this.files)
      }
      return result
    },
    subjectModifier () {
      return `${this.ticket.subject || ''}`
    },
    messageModifier () {
      return `
        ${this.ticket.ticketDetail ? `<p><strong>Incident Detail</strong></p> ${this.ticket.ticketDetail}` : ''}
      `
    },
    isFormDisabled () {
      const user = this.$store.getters['User/user']
      const { ticket } = this
      if (!user || !ticket) {
        return true
      }
      if(user?.id === ticket?.progressBy?.id) {
        return false
      }
      if (user?.role === roleEnum.AGENT || user?.role === roleEnum.SUPERVISOR) {
        if (user?.userTeamId === ticket?.progressBy?.userTeamId) {
          return false
        }
      }

      if (user?.role === roleEnum.SUPER_ADMIN || user?.role === roleEnum.MANAGEMENT || user?.role === roleEnum.MANAGER) {
        if (user?.userTeamId === ticket?.progressBy?.userTeamId || user?.userGroupId === ticket?.progressBy?.userGroupId) {
          return false
        }
      }

      return true
    },
    isFormStatus () {
      return this.includeStatus(this.ticket?.ticketStatus, 'closed', 'void')
    },
  },
  async mounted () {
    try {
      this.isLoaded = false
      this.$store.dispatch('activity/increaseLoading')
      // await this.getCategory()
      await this.getAllOptions()
      await this.getTicketById()
      await this.filterParentCategory()
      if (this.customerDetail) {
        this.ticket.customerName = this.customerDetail?.name ? this.customerDetail?.name : this.ticket.customerName
        // this.ticket.contactName = this.customerDetail.name ? this.customerDetail?.name : this.ticket.customerName
        this.ticket.contactPhoneNo = this.customerDetail.ani || this.customerDetail.companyMobilePhone
       || this.customerDetail.companyOfficePhone || this.ticket.contactPhoneNo
      }
    } finally {
      this.ticketCodeRefer = this.ticket?.ticketCodeRefer
      setTimeout(() => {
        this.$store.dispatch('activity/decreaseLoading')
        this.isFullLoaded = true
      }, 1000)
    }
  },
  methods: {
    ...mapActions({
      deleteTicket: 'ticket/DELETE_TICKET'
      // overwriteTicket: 'ticket/OVERWRITE_TICKET'
    }),
    async getTicketById () {
      try {
        this.$store.dispatch('activity/increaseLoading')
        const { data } = await TicketService.findOne(this.ticketId)
        // this.ticket = data
        this.resolveDate = data?.resolveDate
        this.dueDate = data?.dueDate
        // if (this.ticket.attachFiles) {
        //   this.ticket.attachFiles = JSON.parse(this.ticket.attachFiles)
        // }
        // console.log(data)
        this.$emit('return-ticket', data)
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    async getCategory () {
      const categories = await CategoriesService.findAll()
      this.allRef.categories = categories.data
      this.setOptionLvlOne(categories.data)
    },
    async getAllOptions () {
      try {
        this.$store.dispatch('activity/increaseLoading')
        this.isShowComponent = false
        const categories = await CategoriesService.findAll()
        this.allRef.categories = categories?.data
        const ticketType = await TicketTypeService.findAllType()
        this.allRef.ticketType = ticketType?.data
        const contactChannel = await ContactChannelService.findAllChannel()
        this.allRef.contactChannel = contactChannel?.data
        // const callCategory = await CallCategoryServie.findAllCallCategory()
        const plant = await PlantService.findAllPlant()
        // this.allRef.callCategory = callCategory.data
        this.setOptionLvlOne(categories?.data)
        this.allRef.plant = plant?.data?.map((item) => ({ ...item, code: `${item?.code} - ${item?.name}` }))
      } catch (error) {
        console.error(error)
      } finally {
        this.isShowComponent = true
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    async updateTicket () {
      try {
        const confirm = await this.$refs.confirmModal.show({ description: 'You want to Update ticket ?', variant: 'success' })
        if (confirm) {
          this.$store.dispatch('activity/increaseLoading')
          const payload = {
            ...this.ticket,
            files: this.attachFiles
          }
          if (this.progressBy) {
            payload.progressBy = this.progressBy
          }
          if (payload?.files?.length > 0) {
            const attachFiles = await this.uploadFile(payload?.files)
            if (attachFiles && attachFiles?.length > 0) {
              payload.attachFiles = JSON.stringify(attachFiles)
            }
          } else {
            payload.attachFiles = '[]'
          }
          delete payload.code
          delete payload.id
          delete payload.createdAt
          delete payload.createdBy
          delete payload.updatedAt
          delete payload.updatedBy
          delete payload.status
          delete payload.ani
          delete payload.files
          payload.ticketDetail = resizeBase64ImagesInHTML(payload.ticketDetail)
          payload.ticketResolve = resizeBase64ImagesInHTML(payload.ticketResolve)
          payload.contactPhoneNo = this.$replacePhoneNumber(payload.contactPhoneNo, '+66')
          await TicketService.updateGroup(this.ticketId, payload)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ticket Update Successfully',
              icon: 'EditIcon',
              variant: 'success',
              text: '👋 Ticket has been updated'
            }
          })
          this.resetForm()
          this.getTicketById()
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ticket Update Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: '👋 Ticket has been failed'
          }
        })
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    async sendEmail (payload) {
      try {
        this.$store.dispatch('activity/increaseLoading')
        await MicrosoftService.sendMail({
          subject: `Ticket No. [${this.ticket?.code}] ${payload?.subject}`,
          html: payload?.message,
          to: payload?.to,
          cc: payload?.cc
        })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: 'Email Send Successfully'
          }
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed',
            icon: 'EditIcon',
            variant: 'danger',
            text: 'Email Send Failed'
          }
        })
      } finally {
        this.isFullLoaded = false
        this.isSendEmailModal = false
        this.$nextTick(() => {
          this.isFullLoaded = true
        })
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    async saveAndClosed () {
      try {
        const validate = await this.$refs.TicketAddForm.validate()
        if (validate) {
          const confirm = await this.$refs.confirmModal.show({ description: 'You want to Update ticket ?', variant: 'success' })
          if (confirm) {
            this.$store.dispatch('activity/increaseLoading')
            const payload = {
              ...this.ticket,
              files: this.attachFiles
            }
            if (this.progressBy) {
              payload.progressBy = this.progressBy
            }
            if (payload?.files?.length > 0) {
              const attachFiles = await this.uploadFile(payload?.files)
              if (attachFiles && attachFiles?.length > 0) {
                payload.attachFiles = JSON.stringify(attachFiles)
              }
            } else {
              payload.attachFiles = '[]'
            }
            delete payload.code
            delete payload.id
            delete payload.createdAt
            delete payload.createdBy
            delete payload.updatedAt
            delete payload.updatedBy
            delete payload.status
            delete payload.ani
            delete payload.files
            payload.ticketDetail = resizeBase64ImagesInHTML(payload.ticketDetail)
          payload.ticketResolve = resizeBase64ImagesInHTML(payload.ticketResolve)
            payload.ticketStatus = 'closed'
            payload.contactPhoneNo = this.$replacePhoneNumber(payload.contactPhoneNo, '+66')
            await TicketService.updateGroup(this.ticketId, payload)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ticket Update Successfully',
                icon: 'EditIcon',
                variant: 'success',
                text: '👋 Ticket has been updated'
              }
            })
            this.resetForm()
            this.getTicketById()
            this.$router.push('/ticket')
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ticket Update Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: '👋 Ticket has been failed'
          }
        })
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    async duplicateTicket () {
      try {
        this.$store.dispatch('activity/increaseLoading')
        const payload = {
          ...this.ticket
        }
        payload.ticketStatus = 'open'
        // delete payload.code
        delete payload.id
        delete payload.createdAt
        delete payload.createdBy
        delete payload.updatedAt
        delete payload.updatedBy
        delete payload.status
        delete payload.resolveDate
        delete payload.dueDate
        delete payload.closedDate
        const { data } = await TicketService.duplicateTicket(payload)
        this.ticket = data
        this.$router.replace(`/ticket/detail/${data.id}`)
        this.$router.go(0)
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    async reOpenTicket () {
      try {
        const confirm = await this.$refs.reOpenConfirmModal.show({ description: 'You want to Update ticket ?', variant: 'success' })
        if (confirm) {
          this.$store.dispatch('activity/increaseLoading')
          const payload = {
            remark: this.remark
          }
          await TicketService.reOpenTicket(this.ticketId, payload)
          this.$router.go()
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    async voidTicket () {
      try {
        const confirm = await this.$refs.confirmModal.show({ description: 'You want to void ticket ?' })
        if (confirm) {
          this.$store.dispatch('activity/increaseLoading')
          await TicketService.voidTicket(this.ticketId)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Void Successfully',
              icon: 'EditIcon',
              variant: 'success',
              text: '👋 Ticket has been void'
            }
          })
          this.$router.push('/ticket')
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Void Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: '👋 Void has been failed'
          }
        })
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    async getAllKnowledgeByCategory () {
      try {
        this.$store.dispatch('activity/increaseLoading')
        const categoryId = this.getLastItem()
        if (categoryId) {
          const data = await KnowledgeService.paginate(this.knowledgePagination.page, this.knowledgePagination.limit, {
            categoriesIds: [categoryId]
          })
          this.setPaginationKnowledge(data.page, data.totalDocs)
          this.knowledgeItems = data.data
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    async getKnowledgeByCategory () {
      try {
        this.$store.dispatch('activity/increaseLoading')
        const categoryId = this.getLastItem()
        // console.log(categoryId)
        const { data } = await KnowledgeService.findAllByCategory(categoryId)
        if (data && Array.isArray(data) && data.length > 0) {
          const knowledge = data[0]
          this.ticket.ticketDetail = knowledge.problemDetail
          this.ticket.ticketResolve = knowledge.solutionDetail
        } else {
          this.setInitialKnowledge()
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    async showModal () {
      try {
        this.$store.dispatch('activity/increaseLoading')
        await this.getAllKnowledgeByCategory()
        this.isKnowledgeModal = true
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    async uploadFile (files) {
      try {
        this.$store.dispatch('activity/increaseLoading')
        if (files && files.length > 0 && typeof files === 'object') {
          const arr = []
          for (const item of files) {
            const formData = new FormData()
            formData.append('file', item.file)
            // eslint-disable-next-line no-await-in-loop
            const { data: { data } } = await UploadService.upload(formData)
            arr.push({ name: item.name, url: data?.publicUrl || item?.url })
          }
          return arr
        }
        return []
      } catch (error) {
        console.error(error)
        return []
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    async getResolveAndDue (val) {
      try {
        this.$store.dispatch('activity/increaseLoading')
        const { data } = await CategoriesService.findOne(val)
        if (data) {
          this.ticket.priority = data?.priority
          const calculateDate = dayjs()
            .add(data.resolvedDay, 'd')
            .add(data.resolvedHour, 'h')
            .add(data.resolvedMin, 'm')
            .format('DD MMMM BBBB HH:mm:ss')
          this.resolveDate = calculateDate
          this.dueDate = calculateDate
        }
      } catch (error) {
        console.error(error)
        this.resolveDate = ''
        this.dueDate = ''
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    async referTicket () {
      try {
        const confirm = await this.$refs.referModal.show({ description: 'You want to Update ticket ?', variant: 'success' })
        if (confirm) {
          this.$store.dispatch('activity/increaseLoading')
          const { data } = await TicketService.findByCode(this.ticketCodeRefer)
          if (!data) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Can not find Ticket ${this.ticketCodeRefer}`,
                icon: 'EditIcon',
                variant: 'danger',
                text: '👋 Refer Ticket has been failed'
              }
            })
            this.ticketCodeRefer = this.ticket.ticketCodeRefer
            return true
          }
          this.ticket.ticketCodeRefer = this.ticketCodeRefer
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
      return true
    },
    async transferTicket () {
      try {
        const agentId = await this.$refs.transferModal.show({ description: 'You want to Update ticket ?', variant: 'success' })
        if (agentId && this.ticketId) {
          this.$store.dispatch('activity/increaseLoading')
          await TicketService.transferTicket(this.ticketId, agentId)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully',
              icon: 'EditIcon',
              variant: 'success',
              text: 'Transfer Successfully'
            }
          })
          setTimeout(() => {
            const tickets = this.ticketsStore
            if (Array.isArray(tickets)) {
              const findTicketIndex = tickets?.findIndex((item) => +item?.id === +this.ticketId)
              if (findTicketIndex >= 0) {
                this.deleteTicket(findTicketIndex)
              }
            }
            this.$router.push('/ticket')
          }, 500)
         
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed',
            icon: 'EditIcon',
            variant: 'danger',
            text: 'Transfer Failed'
          }
        })
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    checkRequireCategory (category) {
      if (Array.isArray(category) && category.length > 0) {
        return true
      }
      return false
    },
    onDiscard () {
      this.resetForm()
      this.$router.push('/ticket')
    },
    resetForm () {
      this.files = []
      this.$refs.TicketAddForm.reset()
    },
    openFile () {
      this.$refs.addFiles.click()
    },
    onChangeFile (event) {
      const { files } = event.target
      for (let i = 0; i < files.length; i++) {
        this.files.push({
          file: files[i],
          name: files[i].name,
          url: URL.createObjectURL(files[i])
        })
      }
    },
    removeFile (index, type) {
      if (type === 'attachFiles') {
        this.ticket.attachFiles.splice(index, 1)
      }
      if (type === 'files') {
        this.files.splice(index, 1)
      }
    },
    openAddModal (val) {
      this.addItem = {
        'Ticket Type :': val.incidentType.label,
        'Customer Name :': val.customerName,
        'Contact Name :': val.contactName,
        'Ticket No. :': val.incidentNo
      }
      this.isConfirmModalActive = true
    },
    confirmAdd () {
      this.isSubmitModalActive = true
    },
    selectedItem (parentId, level) {
      const filter = this.findCategory(parentId)
      switch (level) {
      case 1:
        this.lvlOptions.lvlTwoOptions = filter
        this.lvlOptions.lvlThreeOptions = []
        this.lvlOptions.lvlFourOptions = []
        this.lvlOptions.lvlFiveOptions = []
        this.lvlOptions.lvlSixOptions = []
        this.ticket.categoryLv2 = null
        this.ticket.categoryLv3 = null
        this.ticket.categoryLv4 = null
        this.ticket.categoryLv5 = null
        this.ticket.categoryLv6 = null
        break
      case 2:
        this.lvlOptions.lvlThreeOptions = filter
        this.lvlOptions.lvlFourOptions = []
        this.lvlOptions.lvlFiveOptions = []
        this.lvlOptions.lvlSixOptions = []
        this.ticket.categoryLv3 = null
        this.ticket.categoryLv4 = null
        this.ticket.categoryLv5 = null
        this.ticket.categoryLv6 = null
        break
      case 3:
        this.lvlOptions.lvlFourOptions = filter
        this.lvlOptions.lvlFiveOptions = []
        this.lvlOptions.lvlSixOptions = []
        this.ticket.categoryLv4 = null
        this.ticket.categoryLv5 = null
        this.ticket.categoryLv6 = null
        break
      case 4:
        this.lvlOptions.lvlFiveOptions = filter
        this.lvlOptions.lvlSixOptions = []
        this.ticket.categoryLv5 = null
        this.ticket.categoryLv6 = null
        break
      case 5:
        this.lvlOptions.lvlSixOptions = filter
        this.lvlOptions.lvlSixOptions = []
        this.ticket.categoryLv6 = null
        break
      default:
        break
      }
      const result = this.getLastItem()
      if (this.currentParentId !== result) {
        this.currentParentId = result
        this.getResolveAndDue(result)
      }
      // this.getKnowledgeByCategory()
    },
    filterParentCategory () {
      const lastParintId = this.getLastItem()
      const filter = this.allRef.categories.filter((item) => Number(item?.id) === Number(lastParintId))
      const result = [...filter]
      for (let i = 0; i < filter[0]?.level; i++) {
        const fil = this.allRef.categories.filter((item) => item.id === result[i]?.parentId)
        result.push(...fil)
        const filters = this.findCategory(result[i]?.parentId)
        switch (result[i]?.level) {
        case 1:
          this.lvlOptions.lvlOneOptions = filters
          break
        case 2:
          this.lvlOptions.lvlTwoOptions = filters
          break
        case 3:
          this.lvlOptions.lvlThreeOptions = filters
          break
        case 4:
          this.lvlOptions.lvlFourOptions = filters
          break
        case 5:
          this.lvlOptions.lvlFiveOptions = filters
          break
        case 6:
          this.lvlOptions.lvlSixOptions = filters
          break
        default:
          break
        }
      }
      result.forEach((item) => {
        switch (item?.level) {
        case 1:
          this.ticket.categoryLv1 = item.id
          break
        case 2:
          this.ticket.categoryLv2 = item.id
          break
        case 3:
          this.ticket.categoryLv3 = item.id
          break
        case 4:
          this.ticket.categoryLv4 = item.id
          break
        case 5:
          this.ticket.categoryLv5 = item.id
          break
        case 6:
          this.ticket.categoryLv6 = item.id
          break
        default:
          break
        }
      })
    },
    getLastItem () {
      const obj = {
        categoryLv1: this.ticket.categoryLv1,
        categoryLv2: this.ticket.categoryLv2,
        categoryLv3: this.ticket.categoryLv3,
        categoryLv4: this.ticket.categoryLv4,
        categoryLv5: this.ticket.categoryLv5,
        categoryLv6: this.ticket.categoryLv6
      }
      const convertToArray = Object.keys(obj).map((key) => obj[key])
      let result = null
      convertToArray.forEach((item) => {
        if (item && item !== '') {
          result = item
        }
      })
      return result
    },
    setOptionLvlOne (val) {
      if (val) {
        this.lvlOptions.lvlOneOptions = val.filter((item) => item?.level === 1 && item?.visibelGroup?.includes(this.profile?.userGroupId))
      }
    },
    findCategory (parentId) {
      return this.allRef.categories.filter((item) => item.parentId === parentId)
    },
    includeStatus (status, ...target) {
      return target.includes(status)
    },
    selectdKnowledge (val) {
      this.ticket.ticketDetail = val.problemDetail
      this.ticket.ticketResolve = val.solutionDetail
      this.isKnowledgeModal = false
    },
    showSendEmailModal () {
      this.isSendEmailModal = !this.isSendEmailModal
    },
    selectUser (val) {
      const payload = {
        id: val.id,
        username: val.username,
        firstName: val.firstName,
        lastName: val.lastName,
        userGroupId: val.userGroupId,
        userTeamId: val.userTeamId,
        role: val.role
      }
      this.progressBy = payload
    },
    showCurrentPageKnowledge (page) {
      this.setPaginationKnowledge(page)
      this.getAllKnowledgeByCategory()
    },
    setPaginationKnowledge (page, totalDocs) {
      this.knowledgePagination.page = page || this.knowledgePagination.page
      this.knowledgePagination.totalDocs = totalDocs || this.knowledgePagination.totalDocs
    },
    fetchSearchKnowledge () {
      this.setPaginationKnowledge(1)
      this.getAllKnowledgeByCategory()
    },
    chooseTicketType (e) {
      if (this.allRef && this.allRef.ticketType.length > 0) {
        const currentTicketType = this.allRef.ticketType.filter((item) => item.id === e)
        if (currentTicketType && currentTicketType?.length > 0) {
          if (currentTicketType[0]?.isRequiredCategory) {
            this.isRequiredCategory = true
          } else {
            this.isRequiredCategory = false
          }
        } else {
          this.isRequiredCategory = false
        }
      }
    },
    setInitialKnowledge () {
      this.ticket.ticketDetail = ''
      this.ticket.ticketResolve = ''
    }
  }
}
</script>

<style scoped>
::v-deep .ql-container.ql-snow>.ql-editor {
  min-height: 150px;
  max-height: 150px;
  overflow-y: auto;
}

.relative {
  position: relative;
}

.pointer {
  cursor: pointer;
}

.remove-file {
  position: absolute;
  right: -17px;
  bottom: 11px;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
</style>
